import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  Entrant,
  EventPlatform,
  EventStatus,
  FIELDS,
  FIELD_ALL,
  FIELD_FEMALE,
  FIELD_MALE
} from '../lib/model';
import EntrantName from './entrant-name';
import EntrantLocation from './entrant-location';
import NationalityFlag from './nationality-flag';
import AthleteTeams from './athlete-teams';
import { withOrdinalSuffix } from '../lib/strings';
import { normalize } from '../lib/strings';
import { CheckCircleIcon } from '@heroicons/react/solid';

const countryNames = new Intl.DisplayNames(['en'], { type: 'region' });

interface EntrantsListProps {
  entrants: Entrant[];
  event: any; // TODO: Add proper Event type
  actionsDisabled: boolean;
  isVoteContest: boolean;
  hasPerformanceEntrants: boolean;
  onUltraSignup: boolean;
  allPicks: Entrant[];
  femalePicksFull: boolean;
  malePicksFull: boolean;
  onAddPick: (entrant: Entrant) => void;
  onRemovePick: (entrant: Entrant) => void;
  onShowAthleteInfo: (entrant: Entrant) => void;
}

interface PickTableCellProps {
  classNames: string;
  entrant: Entrant;
  entrantPicked: boolean;
  addPick: (entrant: Entrant) => void;
  addPickDisabled: boolean;
  removePick: (entrant: Entrant) => void;
  removePickDisabled: boolean;
}

const PickTableCell = ({
  classNames,
  entrantPicked,
  entrant,
  addPick,
  addPickDisabled,
  removePick,
  removePickDisabled
}: PickTableCellProps) => {
  return (
    <td className={`td-compact text-center text-sm font-medium ${classNames}`}>
      {entrantPicked ? (
        <button
          className="flex items-center rounded p-2 text-xs disabled:text-gray-300"
          onClick={() => removePick(entrant)}
          title="Remove Pick"
          key={`remove-pick-btn-${entrant.id}`}
          disabled={removePickDisabled}
        >
          <CheckCircleIcon className="mr-1 h-4 w-4 flex-shrink-0" aria-hidden="true" />
        </button>
      ) : (
        <button
          className="btn btn-sm btn-primary flex items-center"
          key={`add-pick-btn-${entrant.id}`}
          onClick={() => addPick(entrant)}
          disabled={addPickDisabled}
        >
          Pick
        </button>
      )}
    </td>
  );
};

const EntrantsList: React.FC<EntrantsListProps> = ({
  entrants,
  event,
  actionsDisabled,
  isVoteContest,
  hasPerformanceEntrants,
  onUltraSignup,
  allPicks,
  femalePicksFull,
  malePicksFull,
  onAddPick,
  onRemovePick,
  onShowAthleteInfo
}) => {
  const [queryFilter, setQueryFilter] = useState('');
  const [fieldFilter, setFieldFilter] = useState(FIELD_ALL);
  const [filteredEntrants, setFilteredEntrants] = useState(entrants);

  const picksLookup = useMemo(() => {
    return allPicks.reduce((acc, pick) => {
      acc[pick.id] = true;
      return acc;
    }, {});
  }, [allPicks]);

  const filterEntrants = (qf: string, ff: string) => {
    const query = qf.toLowerCase();
    if (query === '' && ff === FIELD_ALL) {
      return setFilteredEntrants(entrants);
    }

    const matches = (value: string) => {
      return normalize((value || '').toLowerCase()).includes(normalize(query));
    };

    setFilteredEntrants(
      entrants.filter((entrant) => {
        const matchesQuery =
          query === '' ||
          matches(entrant.first_name) ||
          matches(entrant.last_name) ||
          matches(`${entrant.first_name} ${entrant.last_name}`) ||
          matches(entrant.city || '') ||
          matches(entrant.state_or_country || '');

        const matchesField = ff === FIELD_ALL || entrant.field === ff;

        return matchesField && matchesQuery;
      })
    );
  };

  const handleQueryFilter = (query: string) => {
    setQueryFilter(query);
    filterEntrants(query, fieldFilter);
  };

  const handleFieldFilter = (field: string) => {
    setFieldFilter(field);
    filterEntrants(queryFilter, field);
  };

  return (
    <>
      <form className="border-b-1 mb-2 pb-4" onSubmit={(e) => e.preventDefault()}>
        <div className="justify-left flex">
          <label className="form-control mr-2 xl:w-60">
            <div className="label">
              <span className="label-text">
                Filter {hasPerformanceEntrants ? 'Performances' : 'Athletes'} (
                {filteredEntrants.length})
              </span>
            </div>
            <input
              type="text"
              placeholder="Search by name or location"
              value={queryFilter}
              onChange={(e) => handleQueryFilter(e.target.value)}
              className="input input-bordered text-sm"
            />
          </label>
          <label className="form-control w-28 xl:w-60">
            <div className="label">
              <span className="label-text">Field</span>
            </div>
            <select
              className="select select-bordered text-sm"
              aria-label="Select field"
              onChange={(e) => handleFieldFilter(e.target.value)}
            >
              {Object.entries(FIELDS).map((e) => (
                <option key={e[0]} value={e[0]}>
                  {e[1]}
                </option>
              ))}
            </select>
          </label>
        </div>
      </form>

      <div className="overflow-x-scroll rounded-lg shadow lg:overflow-hidden">
        <table className="table-lg table rounded-lg">
          <thead className="dark:bg-base-100 bg-zinc-900 dark:text-base-content text-sm text-white">
            <tr className="border-b border-gray-600">
              <th scope="col" className="table-cell font-medium lg:hidden">
                Pick
              </th>
              {isVoteContest ? (
                hasPerformanceEntrants ? (
                  <>
                    <th className="td-compact font-medium">Athlete</th>
                    <th className="td-compact hidden text-center font-medium sm:table-cell">
                      Nationality
                    </th>
                    <th className="td-compact font-medium">Race</th>
                    <th className="td-compact font-medium">Place</th>
                    <th className="td-compact font-medium">Time</th>
                    <th className="td-compact text-center font-medium">Course Record</th>
                    <th className="td-compact text-center font-medium">Field</th>
                  </>
                ) : (
                  <>
                    <th className="td-compact min-w-[200px] font-medium">Name</th>
                    <th className="td-compact font-medium">Nationality</th>
                    <th className="td-compact text-center font-medium">Field</th>
                    <th className="td-compact font-medium">Team</th>
                  </>
                )
              ) : (
                <>
                  <th className="td-compact font-medium">
                    Name {onUltraSignup ? '(Results)' : ''}
                  </th>
                  <th className="td-compact font-medium">
                    {event.platform === EventPlatform.UTMB ? 'Nationality' : 'Location'}
                  </th>
                  <th className="td-compact text-center font-medium">Field</th>
                  <th className="td-compact font-medium">
                    {onUltraSignup ? 'Age Group' : 'Category'}
                  </th>
                  <th className="td-compact font-medium">
                    {event.rank_label || (onUltraSignup ? 'UltraSignup Rank (%)' : 'UTMB Index')}
                  </th>
                </>
              )}
              <th className="hidden font-medium lg:table-cell">Pick</th>
            </tr>
          </thead>
          <tbody className="dark:bg-base-300 bg-white/50">
            {filteredEntrants.map((entrant) => {
              const entrantPicked = Boolean(picksLookup[entrant.id]);
              const addPickDisabled =
                actionsDisabled ||
                !entrant.started_race ||
                (entrant.field === FIELD_FEMALE && femalePicksFull) ||
                (entrant.field === FIELD_MALE && malePicksFull);

              return (
                <tr
                  key={entrant.id}
                  className={`border-base-200 h-12 border-b ${
                    entrant.started_race
                      ? 'dark:hover:bg-secondary hover:bg-base-200'
                      : 'dark:bg-base-100/40 bg-base-200 line-through'
                  } ${entrantPicked ? 'dark:bg-secondary' : ''}`}
                >
                  <PickTableCell
                    classNames="lg:hidden table-cell"
                    entrant={entrant}
                    entrantPicked={entrantPicked}
                    addPick={onAddPick}
                    addPickDisabled={addPickDisabled}
                    removePick={onRemovePick}
                    removePickDisabled={actionsDisabled}
                  />
                  {isVoteContest ? (
                    hasPerformanceEntrants ? (
                      <>
                        <td className="td-compact py-3">
                          <div className="min-w-[180px] text-sm">
                            <EntrantName
                              entrant={entrant}
                              onClick={() => onShowAthleteInfo(entrant)}
                              showImage
                              largeImage
                            />
                          </div>
                        </td>
                        <td className="td-compact hidden sm:table-cell">
                          <div className="flex justify-center py-3">
                            <NationalityFlag country={entrant.athlete?.country} />
                          </div>
                        </td>
                        <td className="td-compact">
                          <div className="text-sm">
                            <a
                              className="hyperlink underline"
                              href={entrant.result!!.race.results_url}
                              target="_blank"
                            >
                              {entrant.result!!.race.name}
                            </a>
                          </div>
                        </td>
                        <td className="td-compact">
                          <div className="text-sm capitalize">
                            {withOrdinalSuffix(entrant.result!!.place_field)} {entrant.field} |{' '}
                            {withOrdinalSuffix(entrant.result!!.place_overall)} OA
                          </div>
                        </td>
                        <td className="td-compact">
                          <div className="text-sm capitalize">
                            {entrant.result!!.formatted_finish_time}
                          </div>
                        </td>
                        <td className="td-compact">
                          <div className="flex justify-center text-sm">
                            {entrant.result!!.course_record ? '👑' : ''}
                          </div>
                        </td>
                        <td className="td-compact">
                          <div className="text-center text-sm capitalize">{entrant.field}</div>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="td-compact text-sm">
                          <div>
                            <EntrantName
                              entrant={entrant}
                              onClick={() => onShowAthleteInfo(entrant)}
                              showImage
                              largeImage
                            />
                          </div>
                        </td>
                        <td className="td-compact">
                          <div className="">
                            <NationalityFlag country={entrant.athlete?.country} />
                            <span className="ml-2 text-sm">
                              {countryNames.of(entrant.athlete?.country || '')}
                            </span>
                          </div>
                        </td>
                        <td className="td-compact">
                          <div className="text-center text-sm capitalize">{entrant.field}</div>
                        </td>
                        <td className="td-compact py-3">
                          {Boolean(entrant.athlete?.teams?.length) ? (
                            <div>
                              <AthleteTeams teams={entrant.athlete?.teams} />
                            </div>
                          ) : (
                            <div className="h-10" />
                          )}
                        </td>
                      </>
                    )
                  ) : (
                    <>
                      <td className="td-compact text-sm">
                        <div>
                          <EntrantName
                            entrant={entrant}
                            onClick={() => onShowAthleteInfo(entrant)}
                            showImage
                          />
                        </div>
                      </td>
                      <td className="td-compact">
                        <EntrantLocation event={event} entrant={entrant} />
                      </td>
                      <td className="td-compact">
                        <div className="text-center text-sm capitalize">{entrant.field}</div>
                      </td>
                      <td className="td-compact">
                        <div className="text-sm capitalize">{entrant.age_group}</div>
                      </td>
                      <td className="td-compact text-sm">
                        {onUltraSignup
                          ? entrant.pre_race_rank_percentage
                          : entrant.pre_race_rank_percentage
                            ? parseInt(`${entrant.pre_race_rank_percentage}`)
                            : ''}
                      </td>
                    </>
                  )}
                  <PickTableCell
                    classNames="hidden lg:table-cell"
                    entrant={entrant}
                    entrantPicked={entrantPicked}
                    addPick={onAddPick}
                    addPickDisabled={addPickDisabled}
                    removePick={onRemovePick}
                    removePickDisabled={actionsDisabled}
                  />
                </tr>
              );
            })}
            {entrants.length === 0 && (
              <tr>
                <td className="td-compact py-3 text-sm">
                  {event.status === EventStatus.Selected
                    ? 'Entrants not yet available'
                    : 'No matching entries'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EntrantsList;
